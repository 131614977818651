import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { MdPinDrop, MdPhone, MdPhoneIphone, MdEmail } from 'react-icons/md';
import BaseContainer from '../UI/Container';
import LogoCaption from '../../assets/logo.png';

const Wrapper = styled.div`
  background-color: #111;
  padding: 1em 0;
`;

const Container = styled(BaseContainer)`
  @media (min-width: 767.98px) {
    display: flex;
  }
`;

const Content = styled.div`
  margin-top: 0.8999999em;
  text-align: center;
  width: 100%;

  h5 {
    color: #ddd;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;

    p {
      display: inline;
      word-break: normal;
    }

    a,
    p {
      color: #ddd;
    }
  }

  svg {
    color: #ddd;
    height: auto;
    margin-right: 5px;
    vertical-align: middle;

    &:hover {
      color: #fff;
    }
  }

  @media (min-width: 767.98px) {
    margin-top: 0;
  }

  @media (min-width: 991.98px) {
    width: 25.33%;
  }
`;

const Links = styled(Content)``;

const Social = styled(Content)`
  svg {
    width: 40px;
  }
`;

const Logo = styled.div`
  display: none;

  img {
    filter: brightness(0) invert(1);
    width: 75px;
  }

  @media (min-width: 991.98px) {
    display: block;
    width: 23.33%;
  }
`;

export default () => (
  <Wrapper>
    <Container>
      <Logo>
        <img src={LogoCaption} alt='Logo' />
      </Logo>

      <Links>
        <h5>Quick Links</h5>
        <ul>
          <li>
            <a href='/'>Home</a>
          </li>
          <li>
            <a href='/about-us'>About Us</a>
          </li>
          <li>
            <a href='/cancellation-policy'>Cancellation Policy</a>
          </li>
          <li>
            <a href='/reservation-policy'>Reservation Policy</a>
          </li>
        </ul>
      </Links>

      <Links>
        <h5>Contact Us</h5>
        <ul>
          <li>
            <MdPinDrop />
            <p>Pagdalagan Sur, Bauang, La Union, Philippines</p>
          </li>
          <li>
            <MdPhone />
            <p>(+072) 682-9609</p>
          </li>
          <li>
            <MdPhoneIphone />
            <p>(+63) 917-8365-925</p>
          </li>
          <li>
            <MdEmail />
            <p>jorisan.wfresort@yahoo.com</p>
          </li>
        </ul>
      </Links>

      <Social>
        <h5>Connect with Us</h5>
        <OutboundLink href='/' target='_blank'>
          <FaFacebook />
        </OutboundLink>
        <OutboundLink href='/' target='_blank'>
          <FaInstagram />
        </OutboundLink>
      </Social>
    </Container>
  </Wrapper>
);
