import React from 'react';
import styled from 'styled-components';
import SubFooter from './SubFooter';
import Copyright from './Copyright';

const Wrapper = styled.footer`
  padding: 0;
`;

export default () => (
  <Wrapper>
    <SubFooter />
    <Copyright />
  </Wrapper>
);
