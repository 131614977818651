import React from 'react';
import styled from 'styled-components';

const Copyright = styled.div`
  text-align: center;
  padding: 0.7999999em 1em;
  background-color: #000;

  p {
    text-transform: uppercase;
    font-size: 0.7999999em;
    margin: 0;
    color: #aaa;
  }
`;

export default () => (
  <Copyright>
    <p>&copy; 2018 Jorisan Waterfront Resort</p>
  </Copyright>
);
