import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import StyledFooter from '../components/Footer';
import Appbar from '../ui/AppBar';
import './layout.css';

const { Content, Footer } = Layout;

const PublicLayout = ({ children }) => (
  <Layout className='Layout-Wrapper'>
    <Appbar />
    <Content className='Content-Wrapper'>{children}</Content>
    <Footer className='Footer-Wrapper'>
      <StyledFooter />
    </Footer>
  </Layout>
);

PublicLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default PublicLayout;
