import React from 'react';
import { Layout, Icon, Popover } from 'antd';
import LogoCaption from '../../assets/logo-caption_2.png';
import AppMenu from '../AppMenu';
import './app-bar.css';

const { Header } = Layout;

const Appbar = () => {
  return (
    <Header className='AppBar'>
      <div className='AppBar-Logo'>
        <img src={LogoCaption} alt='Jorisan Waterfront Resort with Caption' />
      </div>
      <AppMenu orientation='horizontal' />
      <Popover
        overlayClassName='AppBar-Menu-Mobile'
        content={<AppMenu orientation='inline' />}
        placement='bottomLeft'
        trigger='click'
      >
        <Icon className='AppBar-Menu-Mobile-Button' type='menu' />
      </Popover>
    </Header>
  );
};

export default Appbar;
