import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Menu } from 'antd';
import './app-menu.css';

const SideMenu = ({ orientation }) => {
  return (
    <Menu
      className={orientation !== 'horizontal' ? 'App-Menu-Mobile' : 'App-Menu'}
      mode={orientation}
      defaultSelectedKeys={['1']}
    >
      <Menu.Item className='App-Menu-Button' key='1'>
        Home
        <Link to='/' />
      </Menu.Item>
      <Menu.Item className='App-Menu-Button' key='2'>
        About Us
        <Link to='/about-us' />
      </Menu.Item>
      <Menu.Item className='App-Menu-Button' key='3'>
        Rooms
        <Link to='/rooms' />
      </Menu.Item>
      <Menu.Item className='App-Menu-Button' key='4'>
        Cancellation Policy
        <Link to='/cancellation-policy' />
      </Menu.Item>
      <Menu.Item className='App-Menu-Button' key='5'>
        Reservation Policy
        <Link to='/reservation-policy' />
      </Menu.Item>
      <Menu.Item className='App-Menu-Button--inverse' key='6'>
        Book Now
        <Link to='/contact-us' />
      </Menu.Item>
    </Menu>
  );
};

SideMenu.propTypes = {
  orientation: PropTypes.string.isRequired,
};

export default SideMenu;
