import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  padding: 1.5em 0;
  padding: 0 1.5999999em;

  @media (min-width: 991.98px) {
    width: 1000px;
    margin: 0 auto;
  }
`;

export default Wrapper;
